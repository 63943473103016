import { NextSeo } from 'next-seo';

import { getMenus } from '../lib/menus';
import { getDefaults } from '../lib/defaults';
import { getThemeAssets } from '../lib/themeAssets';
import { getPage } from '../lib/pages';

import { renderGutenbergBlocks } from '../util/blocks';
import { renderShortcodes } from '../util/shortcodes';

import Post from '../layouts/Post';
import SidebarLayout from '../layouts/sidebar';
import EventLayout from '../layouts/event';

export default function Page(props) {
    const {
        shortcodes,
        blocks,
        seo,
        is404,
        blocksJSON,
        layoutType = 'default',
        mediaContactHide = '0',
        optionHideCta = '0',
        displayCareerCta = '0',
        mediaContact = {},
        acf_post_event,
        date = new Date(),
        link
    } = props;

    if (is404) {
        return <></>;
    }

    const { canonical, title, metaDesc } = seo;

    let fixedCanonical = canonical;
    if (canonical !== undefined) {
        if (canonical.includes('cms.enfusion.com')) {
            fixedCanonical = canonical.replace("cms.enfusion.com", "enfusion.com");
        } else if (canonical.includes('enfusion-cms.webworkinprogress.com')) {
            fixedCanonical = canonical.replace("enfusion-cms.webworkinprogress.com", "enfusion.com");
        } else if (canonical.includes('enfusion-api.webworkinprogress.com')) {
            fixedCanonical = canonical.replace("enfusion-api.webworkinprogress.com", "enfusion.webworkinprogress.com");
        }
    }

    let nextSeoProps = {
        canonical: fixedCanonical,
        title: seo.title,
        description: seo.metaDesc,
        openGraph: {
            title: seo.opengraphTitle,
            description: seo.opengraphDescription,
            type: seo.opengraphType,
            article: {
                modifiedTime: seo.opengraphModifiedTime
            }
        }
    };
    nextSeoProps.additionalMetaTags = [
        {
            name: 'referrer',
            content: 'no-referrer-when-downgrade'
        }
    ];

    nextSeoProps.nofollow = seo.metaRobotsNofollow === 'nofollow';
    nextSeoProps.noindex = seo.metaRobotsNoindex === 'noindex';

    if (seo.opengraphImage) {
        nextSeoProps.openGraph.images = [
            {
                url: seo.opengraphImage.sourceUrl,
                alt: seo.opengraphImage.altText,
                width: seo.opengraphImage.mediaDetails.width,
                height: seo.opengraphImage.mediaDetails.height
            }
        ];
    } else {
        /* Fallback to the Twitter card */
        nextSeoProps.openGraph.images = [
            {
                url:
                    'http://enfusion-api.webworkinprogress.com/wp-content/uploads/2021/05/home-preview.png',
                alt: 'Enfusion',
                width: 1900,
                height: 1053
            }
        ];
    }

    if (acf_post_event?.enableEvent && acf_post_event?.eventSidebar) {
        return (
            <EventLayout pageProps={props}>
                <NextSeo {...nextSeoProps} />

                {renderGutenbergBlocks(blocks, props)}
                {renderShortcodes(shortcodes, props)}
            </EventLayout>
        );
    } else if (layoutType === 'sidebar') {
        return (
            <SidebarLayout pageProps={props}>
                <NextSeo {...nextSeoProps} />

                {renderGutenbergBlocks(blocks, props)}
                {renderShortcodes(shortcodes, props)}
            </SidebarLayout>
        );
    } else {
        return (
            <Post pageProps={props}>
                <NextSeo {...nextSeoProps} />

                {renderGutenbergBlocks(blocks, props)}
                {renderShortcodes(shortcodes, props)}
            </Post>
        );
    }
}

export async function getStaticProps(context) {
    const pageData = await getPage('/404-error');

    if (!pageData || !pageData.contentNode) {
        return {
            props: {
                is404: true
            }
        };
    }
    
    const {
        title,
        blocks,
        blocksJSON,
        contentType: {
            node: { name: contentType }
        },
        acf_post_event,
        seo,
        date = '',
        link = '',
        relatedPosts,
        categories
    } = pageData.contentNode;
    const menus = {};

    (getMenus(pageData)).map((menu) => (menus[menu.slug] = menu));
    let options = pageData.options.acf_site_options;
    let defaults = getDefaults(pageData);
    
    const themeAssets = {};
    (getThemeAssets(pageData)).map(
        (item) => (themeAssets[item.id] = item.acf_post_type_theme_assets)
    );

    const props = {
        title,
        link,
        blocks,
        menus,
        options,
        relatedPosts,
        contentType,
        themeAssets,
        categories: pageData?.contentNode?.categories || [],
        defaults,
        layoutType: pageData?.contentNode?.postFields?.layoutType || 'default',
        mediaContactHide:
            pageData?.contentNode?.postFields?.mediaContactHide || '0',
        mediaContact:
            pageData?.contentNode?.mediaContact?.mediaContactSettings || {},
        optionHideCta:
            pageData?.contentNode?.optionHideCta?.hideCtaModule || '0',
        useNewDesign:
            pageData?.contentNode?.optionHideCta?.useNewDesign || '0',
        displayCareerCta: pageData?.contentNode?.careerCta?.displayCareerCta || '0',
        acf_post_event: pageData?.contentNode?.acf_post_event || [],
        date,
        seo,
        perPageFields: pageData?.contentNode?.perPageFields || null
    };

    return {
        props: props
    };
}
